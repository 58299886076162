/*! *********************************************************************
 *
 * Copyright 2019 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 *************************************************************************
 */

import styled from 'styled-components';

import { TabList, Tab } from '@react/react-spectrum/TabList';

/**
 * Custom Spectrum TabList
 * - remove the underline on selected Tabs
 * - add an optional separator between tab item
 */
const StyledTabs = styled(TabList)`
  .spectrum-Tabs-selectionIndicator {
    display: none;
  }

  & .separator {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    margin-right: 30px !important;
    &::after {
      content: '';
      display: block;
      width: 0px;
      height: 24px;
      border-right: 2px solid var(--menu-facets-divider);
      border-radius: 0px;
      position: absolute;
      top: 12px;
      right: 0px;
    }
  }
`;

/**
 * Custom Spectrum Tab
 * - Selected tabs are bolder
 */
const StyledTab = styled(Tab)`
  &.is-selected .active {
    font-weight: 900;
  }
`;

export default StyledTabs;
export { StyledTabs as TabList, StyledTab as Tab };
