/*! *********************************************************************
 *
 * Copyright 2019 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 *************************************************************************
 */

/* eslint-disable no-template-curly-in-string */

/* !!! CommonJS format intended */
/* It's used by NodeJS server as well */

/**
 * @param {string} placeholder
 * @param {string} defaultValue
 */
const parseEnv = (placeholder, defaultValue) => {
  const isNode =
    typeof process !== 'undefined' && process.versions && process.versions.node;

  if (placeholder.startsWith('${env.')) {
    // If on NodeJS (${env.XXXX} placeholders are not usually replaced), then load envvars dynamically
    const envVarName = placeholder.replace('${env.', '').replace('}', '');
    if (isNode) {
      return process.env[envVarName] || defaultValue;
    }

    return process.env[`REACT_APP_${envVarName}`] || defaultValue;
  }

  // placeholder may have been replaced by an empty string (no envvar defined), thus we take the default
  // value in that case.
  return placeholder || defaultValue;
};

exports.API_MODE = parseEnv('${env.API_MODE}', 'production');

exports.API_URL = parseEnv(
  '${env.API_URL}',
  'https://share-api-develop.dev.substance3d.io',
);

exports.GRAPHQL_URL = `${this.API_URL}/beta/graphql`;

exports.CLOUD_PLAYER_URL = parseEnv(
  '${env.CLOUD_PLAYER_URL}',
  'https://cloud-player.substance3d.com',
);

exports.RTE_VIEWER_URL = parseEnv(
  '${env.RTE_VIEWER_URL}',
  'https://rte-web-viewer-develop.dev.substance3d.io',
);

exports.CLOUD_PLAYER_ENABLED =
  parseEnv('${env.CLOUD_PLAYER_ENABLED}', 'true') === 'true';

exports.RTE_VIEWER_ENABLED =
  parseEnv('${env.RTE_VIEWER_ENABLED}', 'true') === 'true';

exports.CORPORATE_WEBSITE_URL = parseEnv(
  '${env.CORPORATE_WEBSITE_URL}',
  'https://www.substance3d.com',
);

exports.FEEDBACK_DISCORD_URL = parseEnv(
  '${env.FEEDBACK_DISCORD_URL}',
  'https://discordapp.com/channels/179919948569640960/635837431295246341',
);

exports.SUBSTANCE_ENGINE_API_URL = parseEnv(
  '${env.SUBSTANCE_ENGINE_API_URL}',
  'https://substance-api-westeurope.azcloud.dev.substance3d.io',
);

exports.BEEHIVE_PROXY_API_URL = parseEnv(
  '${env.BEEHIVE_PROXY_API_URL}',
  'https://beehive-front.azcloud.prod.substance3d.io',
);

exports.CDN_ASSET_PREFIX = parseEnv('${env.CDN_ASSET_PREFIX}', 'share-develop');

exports.BEEHIVE_PROXY_LOADER = exports.CDN_ASSET_PREFIX;

exports.PRODUCTION =
  parseEnv('${env.NODE_ENV}', 'development') === 'production';

exports.PUBLIC_URL = parseEnv(
  '${env.PUBLIC_URL}',
  'https://share-latest.dev.substance3d.io',
);

exports.HOME_TITLE = parseEnv(
  '${env.HOME_TITLE}',
  'Substance 3D Community Assets Homepage',
);

exports.DOCUMENT_TITLE_SUFFIX = parseEnv(
  '${env.DOCUMENT_TITLE_SUFFIX}',
  'on Substance 3D Community Assets',
);

exports.ADOBEID_CLIENT_ID = parseEnv(
  '${env.ADOBEID_CLIENT_ID}',
  'substance-share-staging',
);
exports.IMSLIB2_URL = parseEnv(
  '${env.IMSLIB2_URL}',
  'https://auth-stg1.services.adobe.com/imslib/imslib.js',
);

exports.ASSET_TYPES = parseEnv('${env.ASSET_TYPES}', 'substanceMaterial');

exports.FEDS_SCRIPT_URL = parseEnv(
  '${env.FEDS_SCRIPT_URL}',
  'https://www.dev02.adobe.com/etc.clientlibs/globalnav/clientlibs/base/feds.js',
);

exports.OT_DOMAIN_ID = parseEnv(
  '${env.OT_DOMAIN_ID}',
  'a3713b16-2e78-408d-b62a-29ce17bf764b-test',
);

exports.THUMBNAIL_GUIDELINE_URL = parseEnv(
  '${env.THUMBNAIL_GUIDELINE_URL}',
  'https://docs.substance3d.com/ctn/creating-thumbnails-205362332.html',
);

exports.ADOBE_3D_URL = parseEnv(
  '${env.ADOBE_3D_URL}',
  'https://www.adobe.com/fr/creativecloud/3d-augmented-reality.html',
);

exports.TUTORIALS_SAMPLER_LINKS = parseEnv(
  '${env.TUTORIALS_SAMPLER_LINKS}',
  'https://substance3d.adobe.com/tutorials/?software=Substance%203D%20Sampler&level=0',
);
exports.TUTORIALS_DESIGNER_LINKS = parseEnv(
  '${env.TUTORIALS_DESIGNER_LINKS}',
  'https://substance3d.adobe.com/tutorials/?software=Substance%203D%20Designer&level=0',
);

exports.FEATURE_SUGGESTED_TAGS =
  parseEnv('${env.FEATURE_SUGGESTED_TAGS}', 'false') === 'true';

exports.FEATURE_ENGINE_COMPATIBILITY =
  parseEnv('${env.FEATURE_ENGINE_COMPATIBILITY}', 'false') === 'true';

/**
 * The key of each route is use to generated page title
 */
exports.ROUTES = {
  home: '/community-assets',
  assetsPage: '/community-assets/assets',
  assetsFollowedPage: '/community-assets/followed',
  notFoundPage: '/community-assets/notfound',
  assetDetailPage: '/community-assets/assets/:id',
  assetEditPage: '/community-assets/assets/:id/edit',
  assetViewerPage: '/community-assets/assets/:id/viewer',
  profilePage: '/community-assets/profile',
  profileEditPage: '/community-assets/profile/edit',
  messagesPage: '/community-assets/messages',
  publicProfilePage: '/community-assets/profile/:id',
  assetsDownloadedPage: '/community-assets/profile/:id/downloads',
  assetsLikedPage: '/community-assets/profile/:id/liked',
  authorFollowedPage: '/community-assets/profile/:id/followed',
  helpPage: '/community-assets/help',
  uploadPage: '/community-assets/upload',
  aboutPage: '/community-assets/about',
};

exports.PLAYER_CONFIG = {
  CLOUD_PLAYER_URL: this.CLOUD_PLAYER_URL,
  RTE_VIEWER_URL: this.RTE_VIEWER_URL,
  CLOUD_PLAYER_ENABLED: this.CLOUD_PLAYER_ENABLED,
  RTE_VIEWER_ENABLED: this.RTE_VIEWER_ENABLED,
  CDN_ASSET_PREFIX: this.BEEHIVE_PROXY_LOADER,
};
