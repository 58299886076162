/*! *********************************************************************
 *
 * Copyright 2019 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 *************************************************************************
 */
import { createIntl, createIntlCache } from 'react-intl';

import { i18nLanguageDetector } from '@3di-cloud-web/i18n';
import { getAppLocales, lang } from '@intl/adobe-locales';

import { ADOBE_LOCALE_APP_NAME, DEFAULT_LOCALE } from '../config';
import { footerEvent } from '../utils/Analytics';

function languageDisplayNameList() {
  let displayNameList = [];

  getAppLocales(ADOBE_LOCALE_APP_NAME).forEach((locale) => {
    const cache = createIntlCache();
    const intl = createIntl({ locale: locale }, cache);

    let languageName = intl.formatDisplayName(lang(locale), {
      type: 'language',
    });

    // Uppercase the 1st letter
    languageName = languageName[0].toUpperCase() + languageName.slice(1);

    // Order the list in alphabeta order except putting DEFAULT_LOCALE to the top
    // of the list
    if (locale === DEFAULT_LOCALE) {
      displayNameList.splice(0, 0, {
        locale: locale,
        displayName: languageName,
      });
    } else {
      displayNameList.push({ locale: locale, displayName: languageName });
    }
  });
  return displayNameList;
}

function languageDisplayName(langDisplayNameList, lang) {
  for (const entry of langDisplayNameList) {
    if (entry.locale === lang) {
      return entry.displayName;
    }
  }
  return '';
}

function useLanguage() {
  const language = i18nLanguageDetector.language || DEFAULT_LOCALE;
  const languageList = languageDisplayNameList();
  const languageLabel = languageDisplayName(languageList, language);

  function setLanguage(value) {
    i18nLanguageDetector.changeLanguage(value);
    const from = language;
    const to = value;
    const label = `From ${from} to ${to}`;
    footerEvent({
      action: `Language change to ${label}`,
    });
  }

  return {
    language,
    languageLabel: languageLabel,
    languages: languageList,
    setLanguage,
  };
}

export default useLanguage;
