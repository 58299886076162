/*! *********************************************************************
 *
 * Copyright 2019 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 *************************************************************************
 */

import React from 'react';
import styled from 'styled-components';

import Avatar from '@react/react-spectrum/Avatar';

function UserAvatar({ avatarUrl, size }) {
  return (
    <Container size={size}>
      {avatarUrl ? (
        <Avatar src={avatarUrl} />
      ) : (
        <AvatarPlaceholder size={size} />
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  img {
    height: ${(props) => (props.size === 'L' ? '64px' : '28px')};
    width: ${(props) => (props.size === 'L' ? '64px' : '28px')};
  }
`;

const AvatarPlaceholder = styled.div`
  border-radius: 50%;
  background-color: var(--placeholder-background);
  height: ${(props) => (props.size === 'L' ? '64px' : '28px')};
  width: ${(props) => (props.size === 'L' ? '64px' : '28px')};
`;

export default UserAvatar;
