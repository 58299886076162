/*! *********************************************************************
 *
 * Copyright 2019 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 *************************************************************************
 */

import _get from 'lodash/get';
import _size from 'lodash/size';
import _keys from 'lodash/keys';
import _pick from 'lodash/pick';
import _uniq from 'lodash/uniq';
import _every from 'lodash/every';
import _assign from 'lodash/assign';
import _filter from 'lodash/filter';
import _isString from 'lodash/isString';

import { parse as _parse, stringify as _stringify } from 'query-string';

export const ARRAY_FMT = { arrayFormat: 'comma' };

export function getQueryParam(location, param, defaultVal = '') {
  return _get(_parse(location.search), param, defaultVal);
}

export function handleQueryChange(history, newQuery, newPathname) {
  let query = _parse(_get(history, 'location.search'));
  let pathname = newPathname;

  if (newPathname === undefined) {
    // Do not change location if search query is not modify
    if (_every(_keys(newQuery), (i) => newQuery[i] === query[i])) return;
    pathname = _get(history, 'location.pathname');
  }

  // Merge and remove empty query params
  query = _assign(query, newQuery);
  query = _pick(
    query,
    _filter(_keys(query), (i) => _size(query[i]) > 0),
  );

  history.replace({
    pathname,
    search: _stringify(query),
    state: _get(history, 'location.state'), // keep current state, if any
  });
}

export function formatQueryList(inputVal) {
  let result = [];

  if (Array.isArray(inputVal)) {
    result = inputVal;
  } else {
    if (_isString(inputVal) && inputVal !== '') result = [inputVal];
  }

  return _uniq(result);
}
