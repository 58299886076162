/*! *********************************************************************
 *
 * Copyright 2019 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 *************************************************************************
 */

import React from 'react';
import _map from 'lodash/map';
import {
  DEFAULT_SEGMENTER_FORMATTER_ID,
  useIntl,
  FormattedMessage,
} from '../hooks/useWbrIntl';
import styled from 'styled-components';

import Link from '@react/react-spectrum/Link';
import Button from '@react/react-spectrum/Button';
import Dropdown from '@react/react-spectrum/Dropdown';
import { Menu, MenuItem } from '@react/react-spectrum/Menu';
import GlobeOutlineIcon from '@react/react-spectrum/Icon/GlobeOutline';

import { getLocalizedURL } from '@intl/adobe-locales';

import ReactDOMLink from '../components/Link';

import {
  ADOBE_URL,
  CORPORATE_WEBSITE_CG_URL,
  CORPORATE_WEBSITE_LEGAL_URL,
  CORPORATE_WEBSITE_PRIVACY_URL,
  CORPORATE_WEBSITE_PRIVACY_RIGHTS,
} from '../config';

import SpectrumProvider from './SpectrumProvider';
import { ReactComponent as AdobeLogo } from '../resources/Adobe_logo.svg';
import { footerEvent } from '../utils/Analytics';
import useLanguage from '../hooks/useLanguage';
import { TABLET_MAX_WIDTH } from '../hooks/useDevice';
import { ROUTES } from '../env-config';

function Footer() {
  const intl = useIntl();
  const { language, languageLabel, languages, setLanguage } = useLanguage();

  function handleClickCookieSettings() {
    if (window.adobePrivacy) window.adobePrivacy.showConsentPopup();
  }

  function getActualYear() {
    const date = new Date();
    return date.getFullYear();
  }

  return (
    <StyledFooter>
      <FooterProvider>
        <div className="footer__right">
          <FooterLink
            href={getLocalizedURL(ADOBE_URL, intl.locale)}
            onClick={() => {
              footerEvent({
                action: `Adobe Logo clicked`,
              });
            }}
          >
            <AdobeLogo className="footer__logo" />
          </FooterLink>
          <span>
            <FormattedMessage
              id="footerCopyright"
              defaultMessage="© {date} Adobe. All rights reserved"
              values={{ date: getActualYear() }}
            />
          </span>
        </div>
        <ul className="footer__left-list">
          <li>
            <FooterLink
              href={getLocalizedURL(CORPORATE_WEBSITE_PRIVACY_URL, intl.locale)}
              onClick={() => {
                footerEvent({
                  action: `Privacy clicked`,
                });
              }}
            >
              <FormattedMessage
                id="footerPrivacyLabel"
                defaultMessage="Privacy"
                description="Footer privacy link label"
              />
            </FooterLink>
          </li>
          <li>/</li>
          <li>
            <FooterLink
              href={getLocalizedURL(CORPORATE_WEBSITE_LEGAL_URL, intl.locale)}
              onClick={() => {
                footerEvent({
                  action: `Terms of use clicked`,
                });
              }}
            >
              <FormattedMessage
                id="footerTermsOfUseLabel"
                defaultMessage="Terms of use"
                description="Footer terms of use link label"
              />
            </FooterLink>
          </li>
          <li>/</li>
          <li>
            <FooterLink
              href={getLocalizedURL(
                CORPORATE_WEBSITE_PRIVACY_RIGHTS,
                intl.locale,
              )}
              onClick={() => {
                footerEvent({
                  action: `Sell Personal Information clicked`,
                });
              }}
            >
              <FormattedMessage
                id="footerPersonalInformationLabel"
                defaultMessage="Do not sell or share my personal information"
                description="Footer do not sell personal information link label"
              />
            </FooterLink>
          </li>
          <li>/</li>
          <li>
            <FooterLink
              href={CORPORATE_WEBSITE_CG_URL}
              onClick={() => {
                footerEvent({
                  action: `Community Guideline clicked`,
                });
              }}
            >
              <FormattedMessage
                id="footerCommunityGuidelinesLabel"
                defaultMessage="Community Guidelines"
                description="Footer community guidelines link label"
              />
            </FooterLink>
          </li>
          <li>/</li>
          <li>
            <ReactDOMLink
              to={ROUTES.aboutPage}
              onClick={() => {
                footerEvent({
                  action: `Footer link about page clicked`,
                });
              }}
              className="footer__link"
            >
              {intl.formatMessage({
                id: 'FooterAbout',
                defaultMessage: 'About',
                description: 'about page',
              })}
            </ReactDOMLink>
          </li>
          <li>/</li>
          <li>
            <FooterLink
              id="openCookieModal"
              onClick={handleClickCookieSettings}
            >
              {intl.formatMessage({
                id: 'FooterCookiePreferences',
                defaultMessage: 'Cookie preferences',
                description: 'Adobe privacy cookie settings page',
              })}
            </FooterLink>
          </li>

          <li className="footer__left-list-item--dropdown">
            <Dropdown onSelect={setLanguage}>
              <Button
                quiet
                dropdownTrigger
                variant="action"
                className="footer__button"
                icon={<GlobeOutlineIcon className="footer__globe" />}
              >
                {languageLabel}
              </Button>
              <Menu dropdownMenu>
                {_map(languages, (entry) => (
                  <MenuItem
                    key={entry.locale}
                    value={entry.locale}
                    role="menuitemradio"
                    selected={language === entry.locale}
                  >
                    {intl.formatMessage({
                      id: DEFAULT_SEGMENTER_FORMATTER_ID,
                      defaultMessage: entry.displayName,
                    })}
                  </MenuItem>
                ))}
              </Menu>
            </Dropdown>
          </li>
        </ul>
        <div>
          <FormattedMessage id="appVersion" defaultMessage="Beta version" />
        </div>
      </FooterProvider>
    </StyledFooter>
  );
}

function FooterLink({ href, children, onClick }) {
  return (
    <Link
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      className="footer__link"
      onClick={onClick}
    >
      {children}
    </Link>
  );
}

const StyledFooter = styled.footer`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: var(--footer-height);
  padding: 0 20px;

  background-color: var(--header-background);
  z-index: 10;

  ul.footer__left-list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    list-style: none;
    padding: 0;
    margin: 0;

    & > li {
      display: flex;
      align-items: center;
    }

    & > li:not(:first-child) {
      margin-left: 7px;
    }

    & > li.footer__left-list-item--dropdown {
      div {
        display: flex;
      }

      button.spectrum-ActionButton {
        border: none;
        background-color: transparent;

        &.is-selected {
          border: none;
          background-color: transparent;
        }
      }
    }
  }

  .footer__globe {
    height: 13px;
    margin-top: 1px;
  }

  .footer__button {
    margin: 0;
    padding: 0;
    height: auto;
    min-width: auto;

    &.spectrum-ActionButton {
      color: currentColor;
    }
  }

  .footer__button {
    .spectrum-ActionButton-label {
      padding: 0;
    }
  }

  .spectrum-Link.footer__link {
    color: currentColor;
    text-decoration: none;
  }

  .footer__right {
    display: flex;
    align-items: center;
  }

  .footer__logo {
    height: 13px;
    fill: currentColor;
    margin-right: 4px;
    margin-top: 6.4px;
  }

  @media (max-width: ${TABLET_MAX_WIDTH}px) {
    position: static;
    height: auto;

    .footer__right {
      margin-left: 0;
      white-space: nowrap;
    }

    ul.footer__left-list {
      border-top: 1px solid var(--footer-divider-color);
    }
  }
`;

const FooterProvider = styled(SpectrumProvider)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 var(--header-padding);
  width: 100%;

  @media (max-width: ${TABLET_MAX_WIDTH}px) {
    position: relative;
    flex-direction: column-reverse;
    justify-content: center;
    padding: 0;
  }
`;

export default Footer;
