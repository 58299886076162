/*! *********************************************************************
 *
 * Copyright 2019 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 *************************************************************************
 */

import React, { Component } from 'react';
import _get from 'lodash/get';

import ErrorPage from './pages/ErrorPage';

class ErrorBoundary extends Component {
  state = { hasError: false, error: {} };

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    console.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      const message = _get(this.state, 'error.message');

      return (
        <ErrorPage
          {...(message && { message })}
          errorCode={_get(this.state, 'error.code')}
        />
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
