/*! *********************************************************************
 *
 * Copyright 2019 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 *************************************************************************
 */

import React from 'react';
import styled from 'styled-components';

import { i18nLanguageDetector } from '@3di-cloud-web/i18n';
import Provider from '@react/react-spectrum/Provider';

import { typekitID } from '../IntlProvider';

function SpectrumProvider(props) {
  return (
    <Provider
      theme="darkest"
      scale="medium"
      typekitId={typekitID()}
      className={props.className}
      locale={i18nLanguageDetector.language}
    >
      {props.children}
    </Provider>
  );
}

/**
 * Override the default background so each component that have a provider
 * can easily change their background without using !important override
 * */
const StyledProvider = styled(SpectrumProvider)`
  background: none !important;
`;

export default StyledProvider;
