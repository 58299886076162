/*! *********************************************************************
 *
 * Copyright 2019 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 *************************************************************************
 */

import { useState, useEffect } from 'react';

const MOBILE_MAX_WIDTH = 768;
const TABLET_MAX_WIDTH = 1024;

const useDevice = () => {
  const checkDevice = () => {
    const tabletMediaQuery = window.matchMedia(
      `(max-width: ${TABLET_MAX_WIDTH}px)`,
    );
    const mobileMediaQuery = window.matchMedia(
      `(max-width: ${MOBILE_MAX_WIDTH}px)`,
    );

    return {
      desktop: !tabletMediaQuery.matches,
      tablet: tabletMediaQuery.matches,
      mobile: mobileMediaQuery.matches,
      landscape: window.innerWidth > window.innerHeight,
    };
  };
  const [state, setState] = useState(checkDevice());

  const updateDevice = () => {
    setState(checkDevice());
  };

  useEffect(() => {
    window.addEventListener('resize', updateDevice);
    return () => {
      window.removeEventListener('resize', updateDevice);
    };
    //eslint-disable-next-line
  }, []);

  return state;
};

export { MOBILE_MAX_WIDTH, TABLET_MAX_WIDTH, useDevice };
export default useDevice;
