/*! *********************************************************************
 *
 * Copyright 2019 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 *************************************************************************
 */

import React, { useContext } from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';
import _get from 'lodash/get';
import _map from 'lodash/map';

import { ROUTES } from '../config';
import { createRoute, withActiveRoute } from '../lib/routeUtils';
import FacetsNavigation from '../components/FacetsNavigation';
import UserContext from '../contexts/UserContext';
import useFacets from '../hooks/useFacets';

const Facets = ({ activeRoute }) => {
  const { user, fetching } = useContext(UserContext);
  const { homeConfig, facets: facetsConfig } = useFacets();
  const match = useRouteMatch(ROUTES.publicProfilePage);
  const location = useLocation();

  const profileId = _get(match, 'params.id');
  const isMyProfile = profileId === _get(user, 'id');

  function generateFacets() {
    const activeConfig = facetsConfig[activeRoute];

    // Select route
    const homeGeneratedConfig = homeConfig;
    const config = activeConfig ? activeConfig : homeGeneratedConfig;
    let facets = config.facets;
    if (config.protected && !isMyProfile) {
      facets = homeGeneratedConfig.facets;
    }

    // Create route if there is params
    return _map(facets, (facet) => ({
      ...facet,
      to: createRoute(facet.to, { id: profileId }),
      label: facet.label,
    }));
  }

  if (fetching) {
    return null;
  }

  return <FacetsNavigation key={location.key} items={generateFacets()} />;
};

export default withActiveRoute(Facets);
