/*! *********************************************************************
 *
 * Copyright 2019 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 *************************************************************************
 */

import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link as DomLink, NavLink } from 'react-router-dom';

import { getElementType } from '../lib';

// Import to get CSS Spectrum Style
// eslint-disable-next-line
import SpectrumLink from '@react/react-spectrum/Link';

export default function Link({ variant, children, className, to, ...rest }) {
  const props = {
    className: classNames(
      'spectrum-Link',
      'spectrum-Link-share',
      { [`spectrum-Link--${variant}`]: variant },
      className,
    ),
    to,
    ...rest,
  };

  let ElementType;
  if (rest.as === 'link') {
    ElementType = <DomLink {...props}>{children}</DomLink>;
  } else if (rest.as === 'nav') {
    ElementType = <NavLink {...props}>{children}</NavLink>;
  } else {
    const Type = getElementType(Link, rest);
    ElementType = <Type {...props}>{children}</Type>;
  }

  return ElementType;
}

Link.propTypes = {
  /** Class to add to the Link */
  className: PropTypes.string,
  /** Link variant */
  variant: PropTypes.oneOf(['quiet', 'subtle', 'overBackground', 'inline']),
};

Link.defaultProps = {
  as: 'link',
};

Link.displayName = 'Link';
