/*! *********************************************************************
 *
 * Copyright 2019 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 *************************************************************************
 */

import React, { lazy, useEffect, useRef, useCallback } from 'react';
import { Switch, Route } from 'react-router-dom';
import _omit from 'lodash/omit';

import { ROUTES } from './config';
import { track } from './utils/Analytics';
import { withActiveRoute } from './lib/routeUtils';
import PrivateRoute from './PrivateRoute';

const HelpPage = lazy(() => import('./pages/HelpPage'));
const HomePage = lazy(() => import('./pages/HomePage'));
const ProfilePage = lazy(() => import('./pages/ProfilePage'));
const NotFoundPage = lazy(() => import('./pages/NotFoundPage'));
const AssetDetailPage = lazy(() => import('./pages/AssetDetailPage'));
const AssetEditPage = lazy(() => import('./pages/AssetEditPage'));
const UploadPage = lazy(() => import('./pages/UploadPage'));
const AboutPage = lazy(() => import('./pages/AboutPage'));

const Routing = (props) => {
  const getStringifiedCurrentLocation = useCallback(() => {
    // Removing the key to avoid tracking of the same page multiple time when rerendering for unknwon reason
    return JSON.stringify(_omit(props.location, ['key']));
  }, [props.location]);

  // keep the last known location to avoid multiple track on the same page
  const lastLocation = useRef();

  useEffect(() => {
    // Track current page view
    if (lastLocation.current !== getStringifiedCurrentLocation()) {
      track(props.pageName);
      lastLocation.current = getStringifiedCurrentLocation();
    }
  }, [props.location, props, getStringifiedCurrentLocation]);

  return (
    <Switch>
      <Route
        exact
        path={[ROUTES.home, ROUTES.assetsPage, ROUTES.assetsFollowedPage]}
        component={HomePage}
      />
      <Route
        exact
        path={[ROUTES.assetDetailPage, ROUTES.assetViewerPage]}
        component={AssetDetailPage}
      />
      <PrivateRoute exact path={ROUTES.uploadPage}>
        <UploadPage />
      </PrivateRoute>
      <PrivateRoute exact path={ROUTES.assetEditPage}>
        <AssetEditPage />
      </PrivateRoute>

      <PrivateRoute
        exact
        path={[
          ROUTES.assetsDownloadedPage,
          ROUTES.assetsLikedPage,
          ROUTES.authorFollowedPage,
        ]}
      >
        <ProfilePage />
      </PrivateRoute>
      <Route exact path={ROUTES.publicProfilePage} component={ProfilePage} />
      <Route exact path={ROUTES.helpPage} component={HelpPage} />
      <Route exact path={ROUTES.aboutPage} component={AboutPage} />
      <Route exact path={ROUTES.notFoundPage} component={NotFoundPage} />

      <Route component={NotFoundPage} />
    </Switch>
  );
};

export default withActiveRoute(Routing);
