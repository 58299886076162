/*! *********************************************************************
 *
 * Copyright 2023 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 *************************************************************************
 */

import React from 'react';
import {
  FormattedMessage as ReactFormattedMessage,
  useIntl as ReactUseIntl,
  ReactIntlErrorCode,
} from 'react-intl';

import _map from 'lodash/map';
import styled from 'styled-components';

import { intlSegmenter } from '@g11n-3di/icu-segmenter';

const INTERNAL_SEGMENTER_FORMATTER_ID = '__internal_word_break_formatter__';
const INTERNAL_TAGID_PREFIX = ['tags:', 'keywords:'];

function onIntlError(e) {
  if (
    e.code === ReactIntlErrorCode.MISSING_TRANSLATION &&
    (e.descriptor.id === INTERNAL_SEGMENTER_FORMATTER_ID ||
      INTERNAL_TAGID_PREFIX.some((item) => e.descriptor.id.includes(item)))
  ) {
    return;
  }
  console.error(e);
}

function segmentMessage(sentence) {
  const segmentedWords = intlSegmenter.segment(sentence);

  const jointSegments = _map(
    segmentedWords,
    (segObj) => `${segObj.segment}{wbr}`,
  ).join('');
  return `<segmentContainer>${jointSegments}</segmentContainer>`;
}

function innerFormatMessage(intl, message) {
  if (typeof message !== 'string') {
    return message;
  }

  const segments = segmentMessage(message);
  return intl.formatMessage(
    {
      id: INTERNAL_SEGMENTER_FORMATTER_ID,
      defaultMessage: segments,
    },
    {
      wbr: (
        <React.Fragment>
          <wbr />
        </React.Fragment>
      ),
      segmentContainer: (chunks) => <DisplayBlock>{chunks}</DisplayBlock>,
    },
  );
}

function messageSegmenter(intl, messages) {
  const formattedMessages = [];

  if (typeof messages === 'string') {
    messages = [messages];
  }

  messages.forEach((msg) => {
    if (typeof msg === 'string') {
      const fmtMessage = innerFormatMessage(intl, msg);
      formattedMessages.push(fmtMessage);
    } else {
      let propsChildren = msg.props.children;
      if (propsChildren) {
        if (typeof propsChildren === 'string') {
          propsChildren = [propsChildren];
        }
        const fmtChildren = [];
        propsChildren.forEach((item) => {
          fmtChildren.push(innerFormatMessage(intl, item));
        });

        msg = {
          ...msg,
          props: {
            ...msg.props,
            children: fmtChildren,
          },
        };
      }
      formattedMessages.push(msg);
    }
  });
  return formattedMessages;
}

const useIntl = () => {
  const intl = ReactUseIntl();

  const formatMessage = (descriptor, values, opts) => {
    const formattedMessage = intl.formatMessage(descriptor, values, opts);
    return messageSegmenter(intl, formattedMessage);
  };

  return { ...intl, formatMessage };
};

const FormattedMessage = ({ id, defaultMessage, values }) => {
  const intl = ReactUseIntl();

  return (
    <ReactFormattedMessage
      id={id}
      defaultMessage={defaultMessage}
      values={{
        ...values,
        wbr: (
          <React.Fragment>
            <wbr />
          </React.Fragment>
        ),
        segmentContainer: (chunks) => <DisplayBlock>{chunks}</DisplayBlock>,
      }}
    >
      {(formattedMessages) => messageSegmenter(intl, formattedMessages)}
    </ReactFormattedMessage>
  );
};

const DisplayBlock = styled.span`
  word-break: keep-all;
`;

export {
  INTERNAL_SEGMENTER_FORMATTER_ID as DEFAULT_SEGMENTER_FORMATTER_ID,
  onIntlError,
  useIntl,
  FormattedMessage,
};
