/*! *********************************************************************
 *
 * Copyright 2019 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 *************************************************************************
 */

import React, { useReducer, lazy } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { ApolloProvider } from '@apollo/react-hooks';

import Routing from './Routing';
import ErrorBoundary from './ErrorBoundary';

import { NotificationProvider } from './components/NotificationContext';
import { AssetsContext, assetsReducer } from './components/AssetsContext';
import { AssetProvider } from './contexts/AssetContext';

import { IntlProvider } from './IntlProvider';
import apolloClient from './utils/apollo-client';
import { UserState } from './contexts/UserContext';
import { DownloadManager } from './components/DownloadContext';
import GlobalLayout from './layout/GlobalLayout';

import './lib/ims';

const CookiesConsent = lazy(() => import('./layout/CookiesConsent'));

/**
 * Contains all the application configuration
 * No style in here
 */
function App() {
  const [assetsState, assetsDispatch] = useReducer(assetsReducer, {
    assets: [],
  });

  return (
    <IntlProvider>
      <ApolloProvider client={apolloClient}>
        <UserState>
          <DownloadManager>
            <Router>
              <ErrorBoundary>
                <NotificationProvider>
                  <AssetProvider>
                    <AssetsContext.Provider
                      value={{
                        state: assetsState,
                        dispatch: assetsDispatch,
                      }}
                    >
                      <GlobalLayout>
                        <Route render={(props) => <Routing {...props} />} />
                        <CookiesConsent />
                      </GlobalLayout>
                    </AssetsContext.Provider>
                  </AssetProvider>
                </NotificationProvider>
              </ErrorBoundary>
            </Router>
          </DownloadManager>
        </UserState>
      </ApolloProvider>
    </IntlProvider>
  );
}

export default App;
