/*! *********************************************************************
 *
 * Copyright 2019 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 *************************************************************************
 */

import React, { useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';

import Link from '@react/react-spectrum/Link';
import AdobeIcon from '@react/react-spectrum/Icon/AdobeLogo';
import { getLocalizedURL } from '@intl/adobe-locales';

import { ROUTES, ADOBE_3D_URL } from '../config';

import useWindowSize from '../hooks/useWindowSize';
import { headerEvent } from '../utils/Analytics';
import SpectrumProvider from './SpectrumProvider';
import UserMenuButton from './UserMenuButton';
import UploadButton from './upload/UploadButton';
import Facets from '../components/Facets';
import useDevice, {
  TABLET_MAX_WIDTH,
  MOBILE_MAX_WIDTH,
} from '../hooks/useDevice';
import { ReactComponent as Logo } from '../resources/CommunityAssets_logo.svg';

function Header({ className }) {
  const windowSize = useWindowSize();
  const { mobile } = useDevice();
  const intl = useIntl();

  const handleLogoClick = () => {
    headerEvent({
      action: `Clicked logo`,
    });
  };

  const smallHeader = useMemo(
    () => windowSize.width <= 1600,
    [windowSize.width],
  );

  return (
    <StyledHeader className={className}>
      <StyledSpectrumProvider>
        <StyledNav smallGrid={smallHeader}>
          <MainTitle onlyLogo={smallHeader}>
            <NavLink
              to={ROUTES.home}
              onClick={handleLogoClick}
              className="header-title-link"
            >
              <Logo className="header-title-link__logo" />
              <h1 className="header-title-link__text">
                Adobe Substance 3D Community Assets
              </h1>
            </NavLink>
          </MainTitle>

          <FacetsContainer alignLeft={smallHeader}>
            <Facets />
          </FacetsContainer>

          <HeaderActions>
            <StyledUploadButton minimalist={mobile} />
            <UserMenuButton {...{ windowSize }} />
            <Link
              href={getLocalizedURL(ADOBE_3D_URL, intl.locale)}
              target="_blank"
              className="header_adobe-link"
            >
              <AdobeIcon size="M" />
              {!mobile && (
                <FormattedMessage
                  id="header3dAndAr"
                  defaultMessage="3D & AR"
                  description="Button to redirect the user to the adobe 3d page"
                />
              )}
            </Link>
          </HeaderActions>
        </StyledNav>
      </StyledSpectrumProvider>
    </StyledHeader>
  );
}

const StyledNav = styled.nav`
  --header-logo-width: 30px;
  --header-logo-margin: 12px;

  display: grid;
  grid-template-columns: ${(props) =>
      props.smallGrid
        ? css`calc(var(--header-logo-width) + var(--header-logo-margin))`
        : '1fr'} 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-template-areas: 'logo facets actions';
  align-items: center;
  height: var(--header-height);
`;
const FacetsContainer = styled.div`
  grid-area: facets;
  display: flex;
  justify-content: ${(props) => (props.alignLeft ? 'left' : 'center')};
  align-self: center;
`;

const HeaderActions = styled.div`
  grid-area: actions;
  display: flex;
  align-items: center;
  justify-self: end;

  &::after {
    content: '';
    display: block;
    width: 0px;
    height: 24px;
    border-left: 2px solid var(--color-gray-400);
    position: relative;
    left: calc(-68px - 26px);
  }

  @media (max-width: ${MOBILE_MAX_WIDTH}px) {
    &::after {
      display: none;
    }
  }

  .spectrum-Link.header_adobe-link {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--text-color);
    text-decoration: initial;
    margin-left: 44px;
    white-space: nowrap;

    &:hover {
      color: var(--text-color);
      text-decoration: initial;
    }

    & > .spectrum-Icon {
      width: 16px;
      height: 16px;
      margin-right: 4px;
    }

    @media (max-width: ${MOBILE_MAX_WIDTH}px) {
      width: 32px;
      height: 32px;
      margin-left: 0;

      & > .spectrum-Icon {
        margin-right: 0;
      }
    }
  }
`;

const StyledUploadButton = styled(UploadButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
`;

const StyledSpectrumProvider = styled(SpectrumProvider)`
  padding: 0px 24px;

  @media (max-width: ${TABLET_MAX_WIDTH}px) {
    padding: 0px 20px;
  }
`;

const StyledHeader = styled.header`
  position: sticky;
  top: 0;
  width: 100%;
  height: var(--header-height);
  background: var(--header-background);
  z-index: 100;

  .spectrum-Button {
    white-space: nowrap;
  }

  @media (max-width: ${TABLET_MAX_WIDTH}px) {
    .spectrum-Tool + .spectrum-Tool {
      margin: 0;
    }
  }
`;

const MainTitle = styled.div`
  & > .header-title-link {
    display: flex;
    align-items: center;
    text-decoration: initial;
    color: currentColor;

    & > .header-title-link__logo {
      height: var(--header-logo-width);
      margin-right: var(--header-logo-margin);
    }

    & > .header-title-link__text {
      display: ${(props) => (props.onlyLogo ? 'none' : 'block')};
      font-size: 22px;
      white-space: nowrap;
      margin: 0;
    }
  }
`;

export default Header;
