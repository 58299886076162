/*! *********************************************************************
 *
 * Copyright 2019 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 *************************************************************************
 */

import React, { useState, useEffect, useRef } from 'react';
import { IntlProvider as ReactIntlProvider } from 'react-intl';

import moment from 'moment';

import { i18nLanguageDetector } from '@3di-cloud-web/i18n';
import { getAppLocale, getAppLocales } from '@intl/adobe-locales';
import { TypeKitFontProvider, getTypeKitID } from '@g11n-3di/font';
import { intlSegmenter } from '@g11n-3di/icu-segmenter';

import { onIntlError } from './hooks/useWbrIntl';

import ims from './lib/ims';

import {
  ADOBE_LOCALE_APP_NAME,
  DEFAULT_LOCALE,
  CJK_LOCALES,
  LOCALE_QUERY,
} from './config';

const availableLocales = getAppLocales(ADOBE_LOCALE_APP_NAME);

i18nLanguageDetector.init({
  fallbackLng: DEFAULT_LOCALE,
  availableLng: availableLocales,
});

async function loadTranslations(locale, fallbackLng = null) {
  if (availableLocales.indexOf(locale) === -1) locale = fallbackLng;

  let module;

  try {
    module = await import(`./lang/${locale}.json`);
  } catch (e) {
    // translation module not found : loading default one
    console.error({ e });
    // if fallback has not been setted and in error
    // will provide an empty object to force using defaultMessage in code.
    if (fallbackLng) {
      module = await loadTranslations(fallbackLng);
    } else {
      module = { default: {} };
    }
  }

  return { messages: module.default, locale };
}

function enableItalic(locale) {
  return !CJK_LOCALES.includes(locale);
}

function typekitID() {
  return getTypeKitID(i18nLanguageDetector.language);
}

const IntlProvider = ({ children }) => {
  const [locale, setLocale] = useState(null);
  const [messages, setMessages] = useState(null);
  const refLocaleQuery = useRef(i18nLanguageDetector.language);

  if (locale) moment.locale(locale);

  const loadMessages = async (askedLocale) => {
    await intlSegmenter.changeLocale(askedLocale);

    const { messages, locale } = await loadTranslations(
      askedLocale,
      i18nLanguageDetector.fallbackLng,
    );
    setMessages(messages);
    setLocale(locale);
    ims.locale = locale;
  };

  useEffect(() => {
    loadMessages(i18nLanguageDetector.language);
    i18nLanguageDetector.on('i18nLngChanged', loadMessages);
    return () => {
      i18nLanguageDetector.off('i18nLngChanged', loadMessages);
    };
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const localeParam = searchParams.get(LOCALE_QUERY);

    if (localeParam !== null) {
      // Get formatted locale tag, or return fallback locale `en-US` if queried
      // locale is not supported yet
      const appLocale = getAppLocale(
        ADOBE_LOCALE_APP_NAME,
        localeParam,
        DEFAULT_LOCALE,
      );
      if (refLocaleQuery.current !== appLocale) {
        refLocaleQuery.current = appLocale;
        i18nLanguageDetector.changeLanguage(appLocale);
      }
    }
  }, [refLocaleQuery]);

  if (messages === null || locale === null) return null;

  return (
    <ReactIntlProvider
      locale={locale}
      defaultLocale={i18nLanguageDetector.fallbackLng}
      messages={messages}
      onError={onIntlError}
    >
      <TypeKitFontProvider locale={locale} />
      {children}
    </ReactIntlProvider>
  );
};

export { IntlProvider, enableItalic, typekitID };
