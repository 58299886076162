/*! *********************************************************************
 *
 * Copyright 2019 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 *************************************************************************
 */

import React from 'react';
import styled from 'styled-components';

import Main from './Main';

/**
 * Single page layout for error/upload/help page
 * @param {Object} content the layout content
 */
const SinglePageLayout = ({ content }) => {
  return <MainContainer className="content-height">{content}</MainContainer>;
};

const MainContainer = styled(Main)`
  position: relative;
  margin-bottom: var(--footer-height);

  & > .spectrum-CircleLoader {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;

export default SinglePageLayout;
