/*! *********************************************************************
 *
 * Copyright 2019 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 *************************************************************************
 */

import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import _findIndex from 'lodash/findIndex';
import _map from 'lodash/map';
import styled from 'styled-components';

import Select from '@react/react-spectrum/Select';

import { headerEvent } from '../utils/Analytics';
import { TabList, Tab } from './TabList';
import useWindowSize from '../hooks/useWindowSize';

function FacetsNavigation({ items, className, ...rest }) {
  const history = useHistory();
  const location = useLocation();
  const intl = useIntl();
  const windowSize = useWindowSize();
  const isMobile = useMemo(() => windowSize.width < 1110, [windowSize.width]);
  const currentLocationIdx = _findIndex(
    items,
    (i) => i.to === location.pathname,
  );
  const itemIdx = currentLocationIdx !== -1 ? currentLocationIdx : 99999;

  const handleFacetClick = (value) => () => {
    headerEvent({
      action: `Clicked on ${value}`,
    });
  };

  if (isMobile) {
    const onSelectChange = (e) => {
      handleFacetClick(e)();
      history.push(e);
    };

    return (
      <MySelect
        quiet
        closeOnSelect
        options={_map(items, (item) => ({
          value: item.to,
          label: item.label,
        }))}
        {...(items[itemIdx] && { value: items[itemIdx].to })}
        placeholder={intl.formatMessage({
          id: 'facetsNavigationMenuPlaceholder',
          defaultMessage: 'Menu',
          description: 'Mobile placeholder for the facet menu',
        })}
        onChange={onSelectChange}
      />
    );
  }

  return (
    <>
      {items && items.length > 0 && (
        <TabList quiet aria-label="Default" selectedIndex={itemIdx}>
          {_map(items, (item, i) => (
            <Tab
              key={`facets-navigation-item-${i}`}
              onClick={handleFacetClick(item.to)}
            >
              <NavLink className={item.className} to={item.to}>
                {item.label}
              </NavLink>
            </Tab>
          ))}
        </TabList>
      )}
    </>
  );
}

FacetsNavigation.propTypes = {
  /** List of links*/
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired,
    }),
  ),
};

FacetsNavigation.defaultProps = {
  items: [],
};

const MySelect = styled(Select)`
  &&& {
    min-width: unset;
  }
`;

export default FacetsNavigation;
