/*! *********************************************************************
 *
 * Copyright 2019 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 *************************************************************************
 */

import { useContext } from 'react';
import PropTypes from 'prop-types';
import { useIntl, defineMessage } from 'react-intl';
import styled from 'styled-components';

import OverlayTrigger from '@react/react-spectrum/OverlayTrigger';
import Popover from '@react/react-spectrum/Popover';
import Link from '@react/react-spectrum/Link';

import UserContext from '../contexts/UserContext';

function NotLoggedPopover({ children, definedMessage }) {
  const intl = useIntl();
  const { login, signUp, user } = useContext(UserContext);

  if (user) {
    return children;
  }

  return (
    <OverlayTrigger trigger="click" placement="bottom">
      {children}
      <Popover variant="default">
        <PopoverContent>
          {intl.formatMessage(definedMessage, {
            logIn: (chunks) => <Link onClick={() => login()}>{chunks}</Link>,
            create: (chunks) => <Link onClick={signUp}>{chunks}</Link>,
          })}
        </PopoverContent>
      </Popover>
    </OverlayTrigger>
  );
}

const PopoverContent = styled.div`
  max-width: 225px;
`;

NotLoggedPopover.propTypes = {
  definedMessage: PropTypes.shape({
    id: PropTypes.string.isRequired,
    defaultMessage: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  }),
};

NotLoggedPopover.defaultProps = {
  definedMessage: defineMessage({
    id: 'NotLoggedPopoverDefaultMessage',
    defaultMessage:
      '<logIn>Log In</logIn> or <create>Create an Account</create> to perform this action',
    description: 'Default message for popover for unsigned user',
  }),
};

export default NotLoggedPopover;
