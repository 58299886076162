/*! *********************************************************************
 *
 * Copyright 2019 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 *************************************************************************
 */

import React from 'react';
import styled from 'styled-components';
import classnames from 'classnames';

function Main({ children, className }) {
  return (
    <MainContent className={classnames(className)}>{children}</MainContent>
  );
}

const MainContent = styled.main``;

export default Main;
