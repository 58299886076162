/*! *********************************************************************
 *
 * Copyright 2019 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 *************************************************************************
 */

import React, { useEffect, useReducer } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import _map from 'lodash/map';

import userQuery from '../graphql/user.gql';
import { i18nLanguageDetector } from '@3di-cloud-web/i18n';
import { imsEvents, IMS_EVENTS } from '@3di-cloud-web/ims';
import ims from '../lib/ims';
import { PUBLIC_URL } from '../env-config';

const UserContext = React.createContext({});

export const UserProvider = UserContext.Provider;

const defaultUser = {
  onBehalfOf: null,
  organizations: [],
  roles: ['viewer'],
  type: `adobe`,
  profile: { followed: [] },
};

const defaultAccount = {
  assetIds: [],
  points: 0,
  licenses: {
    unlimited: {
      status: 'none',
      expirationDate: null,
    },
    standard: {
      status: 'none',
      expirationDate: null,
    },
    premium: {
      status: 'none',
      expirationDate: null,
    },
  },
};

const initialState = {
  user: null,
  account: null,
  jobTitle: null,
  industry: null,
  fetching: false,
  error: null,
  logged: false,
  firstLoad: true,
};

const userReducer = (state, { type, payload }) => {
  switch (type) {
    case 'reset':
      return {
        initialState,
        fetching: false,
        firstLoad: false,
      };
    case 'fetching':
      return { ...state, fetching: true, error: null, firstLoad: false };
    case 'update':
      return {
        logged: true,
        fetching: false,
        error: null,
        account: payload.account,
        user: payload.user,
        jobTitle: payload.jobTitle,
        industry: payload.industry,
        firstLoad: false,
      };
    case 'purchaseAsset':
      return {
        ...state,
        account: {
          ...state.account,
          assetIds: [...state.account.assetIds, payload.assetId],
        },
      };
    case 'error':
      return { ...state, fetching: false, error: payload, firstLoad: false };
    case 'updateWithBackError':
      return {
        ...state,
        fetching: false,
        firstLoad: false,
        user: payload.user,
        account: payload.account,
        error: payload.error,
      };
    default:
      return state;
  }
};

const UserState = ({ children }) => {
  const [state, dispatch] = useReducer(userReducer, initialState);

  const [fetchUser, { data, refetch, loading, error }] = useLazyQuery(
    userQuery,
    {
      fetchPolicy: 'network-only',
    },
  );

  const logout = () => {
    ims.signOut({ redirect_uri: PUBLIC_URL });
    dispatch({ type: 'reset' });
  };

  const login = (options) => {
    dispatch({ type: 'fetching' });
    ims.signIn({ locale: i18nLanguageDetector.language, ...options });
  };

  const signUp = async () => {
    dispatch({ type: 'fetching' });
    ims.signUp({ locale: i18nLanguageDetector.language });
  };

  const isLogged = () => !!state.user;

  const _fetchUser = () => {
    try {
      fetchUser();
    } catch (e) {
      console.error({ e });
      dispatch({ type: 'error', payload: e });
    }
  };

  const _reset = () => {
    dispatch({ type: 'reset' });
  };

  const refresh = () => {
    refetch?.();
  };

  const purchaseAsset = (asset) => {
    dispatch({ type: 'purchaseAsset', payload: { assetId: asset.id } });
  };

  /**
   * Restore session on context initilization
   */
  useEffect(() => {
    imsEvents.on(IMS_EVENTS.TOKEN_AVAILABLE, _fetchUser);
    imsEvents.on(IMS_EVENTS.TOKEN_EXPIRED, _reset);

    // ims instance is created before useEffect takes place.
    // refreshToken is used to be sure to trigger events ASAP
    ims.refreshToken();
    return () => {
      imsEvents.off(IMS_EVENTS.TOKEN_AVAILABLE, _fetchUser);
      imsEvents.off(IMS_EVENTS.TOKEN_EXPIRED, _reset);
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Store user information
   */
  useEffect(() => {
    if (data) {
      dispatch({
        type: 'update',
        payload: {
          user: data.user,
          account: data.account,
          jobTitle: _map(data.jobTitle, (job) => job.key),
          industry: _map(data.industry, (industry) => industry.key),
        },
      });
    }
  }, [data]);

  /**
   * Trigger an event after a successful login
   */
  useEffect(() => {
    if (state.logged) {
      var event = new CustomEvent('USER_LOGGED');
      document.dispatchEvent(event);
    }
  }, [state]);

  useEffect(() => {
    if (loading) dispatch({ type: 'fetching' });
  }, [loading]);

  useEffect(() => {
    async function onError() {
      console.error({ error });

      const adobeToken = await ims.refreshToken();

      if (!adobeToken?.tokenInfo.token) {
        dispatch({ type: 'error', payload: error });
        return;
      }

      const {
        avatarUrl,
        userId: id,
        preferred_languages: languages,
        displayName: name,
      } = await ims.getProfile();

      dispatch({
        type: 'updateWithBackError',
        payload: {
          user: {
            ...defaultUser,
            avatarUrl,
            id,
            languages,
            name,
          },
          account: defaultAccount,
          error: error?.graphQLErrors[0]?.extensions?.code || 500,
        },
      });
    }

    if (error && !state.error) {
      onError();
    }
  }, [error, state.error]);

  return (
    <UserProvider
      value={{
        user: state.user,
        account: state.account,
        jobTitle: state.jobTitle,
        industry: state.industry,
        error: state.error,
        fetching: state.fetching || state.firstLoad,
        refresh,
        isLogged,
        login,
        logout,
        signUp,
        purchaseAsset,
      }}
    >
      {children}
    </UserProvider>
  );
};

export default UserContext;
export { UserState, UserContext };
