/*! *********************************************************************
 *
 * Copyright 2019 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 *************************************************************************
 */

import { useIntl } from 'react-intl';

import { ROUTES } from '../config';

function useFacets() {
  const intl = useIntl();

  const defaultConfig = {
    protected: false,
  };

  const homeConfig = {
    ...defaultConfig,
    facets: [
      {
        label: intl.formatMessage({
          id: 'homeAssetsAll',
          defaultMessage: 'All Assets',
        }),
        to: ROUTES.home,
      },
      {
        label: intl.formatMessage({
          id: 'homeAssetsFollowed',
          defaultMessage: 'Followed',
        }),
        to: ROUTES.assetsFollowedPage,
      },
    ],
  };

  const profileConfig = {
    ...defaultConfig,
    protected: true,
    facets: [
      {
        label: intl.formatMessage({
          id: 'homeAssetsAll',
          defaultMessage: 'All Assets',
        }),
        to: ROUTES.home,
        className: 'separator',
      },
      {
        label: intl.formatMessage({
          id: 'profilePageContributions',
          defaultMessage: 'Contributions',
        }),
        to: ROUTES.publicProfilePage,
      },
      {
        label: intl.formatMessage({
          id: 'profilePageLiked',
          defaultMessage: 'Liked',
        }),
        to: ROUTES.assetsLikedPage,
      },
      {
        label: intl.formatMessage({
          id: 'profilePageDownloaded',
          defaultMessage: 'Downloaded',
        }),
        to: ROUTES.assetsDownloadedPage,
      },
      {
        label: intl.formatMessage({
          id: 'profilePageFollowed',
          defaultMessage: 'Followed Artists',
        }),
        to: ROUTES.authorFollowedPage,
      },
    ],
  };

  const facets = {
    [ROUTES.home]: homeConfig,
    [ROUTES.followed]: homeConfig,
    [ROUTES.publicProfilePage]: profileConfig,
    [ROUTES.assetsLikedPage]: profileConfig,
    [ROUTES.assetsDownloadedPage]: profileConfig,
    [ROUTES.authorFollowedPage]: profileConfig,
  };

  return { homeConfig, profileConfig, facets };
}

export default useFacets;
