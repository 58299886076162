/*! *********************************************************************
 *
 * Copyright 2019 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 *************************************************************************
 */

import React, { useContext } from 'react';
import { useIntl, defineMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import Button from '@react/react-spectrum/Button';
import UploadIcon from '@react/react-spectrum/Icon/UploadToCloud';

import { ROUTES } from '../../config';
import UserContext from '../../contexts/UserContext';
import { headerEvent } from '../../utils/Analytics';
import { MOBILE_MAX_WIDTH } from '../../hooks/useDevice';
import NotLoggedPopover from '../NotLoggedPopover';

function UploadButton(props) {
  const intl = useIntl();
  const history = useHistory();
  const { fetching } = useContext(UserContext);

  function handleClickUpload() {
    headerEvent({
      action: 'Clicked on upload',
    });
    history.push(ROUTES.uploadPage);
  }

  const popoverMessage = defineMessage({
    id: 'uploadButtonPopover',
    defaultMessage:
      '<logIn>Log In</logIn> or <create>Create an Account</create> to upload an asset and share it to the community.',
    description: 'Upload popover for unsigned user',
  });

  return (
    <NotLoggedPopover definedMessage={popoverMessage}>
      <StyledButton
        icon={<UploadIcon />}
        label={
          !props.minimalist &&
          intl.formatMessage({
            id: 'headerActionUpload',
            defaultMessage: 'Upload',
            description: 'Upload button label on header bar',
          })
        }
        variant={props.minimalist ? 'quiet' : 'cta'}
        onClick={handleClickUpload}
        disabled={fetching}
        {...props}
      />
    </NotLoggedPopover>
  );
}

const StyledButton = styled(Button)`
  @media (max-width: ${MOBILE_MAX_WIDTH}px) {
    min-width: auto;
    padding: 0 6px;
  }
`;

UploadButton.propTypes = {};
UploadButton.defaultProps = {};
export default UploadButton;
