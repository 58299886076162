/*! *********************************************************************
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 *************************************************************************
 */

import React, { useState, useContext, useEffect } from 'react';
import { Route, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import Wait from '@react/react-spectrum/Wait';
import Button from '@react/react-spectrum/Button';
import IllustratedMessage from '@react/react-spectrum/IllustratedMessage';
import LoginIcon from '@react/react-spectrum/Icon/Login';
import { FormattedMessage } from 'react-intl';

import SinglePageLayout from './layout/SinglePageLayout';
import UserContext from './contexts/UserContext';
import { ROUTES } from './env-config';

function PrivateRoute({ children, ...rest }) {
  const { fetching, isLogged, login } = useContext(UserContext);
  const [state, setState] = useState({ fetching: true, logged: false });
  const history = useHistory();

  useEffect(() => {
    if (!fetching) {
      setState({ fetching: false, logged: isLogged() });
    }
  }, [fetching, isLogged]);

  if (state.fetching) return <SinglePageLayout content={<Wait size="L" />} />;

  return (
    <Route
      {...rest}
      render={() =>
        !state.logged ? (
          <SinglePageLayout
            content={
              <StyledIllustratedMessage
                heading={
                  <FormattedMessage
                    id="privateRouteTitle"
                    defaultMessage="Authentication required"
                    description="Title of the illustrated message appearing on page requiring authentication when the user is not connected for exemple /upload"
                  />
                }
                description={
                  <>
                    <p>
                      <FormattedMessage
                        id="privateRouteDescription"
                        defaultMessage="In order to acces this page you need to be authenticated.
                      You can choose to login or to be redirected to the home
                      page"
                        description="description text of the illustrated message appearing on page requiring authentication when the user is not connected for exemple /upload"
                      />
                    </p>
                    <Button
                      variant="secondary"
                      onClick={() => {
                        history.push(ROUTES.home);
                      }}
                    >
                      <FormattedMessage
                        id="privateRouteCancelButton"
                        defaultMessage="Cancel"
                        description="Cancel button text appearing on page requiring authentication when the user is not connected for exemple /upload, this button redirect the user to the homepage"
                      />
                    </Button>
                    <Button variant="cta" onClick={() => login()}>
                      <FormattedMessage
                        id="privateRouteLoginButton"
                        defaultMessage="Login"
                        description="Login button text appearing on page requiring authentication when the user is not connected for exemple /upload, this button redirect the user to the Adobe login page"
                      />
                    </Button>
                  </>
                }
                illustration={<LoginIcon size="L" />}
              />
            }
          />
        ) : (
          children
        )
      }
    />
  );
}

const StyledIllustratedMessage = styled(IllustratedMessage)`
  height: 70vh;

  svg {
    width: 200px;
    height: 200px;
  }
`;

export default PrivateRoute;
