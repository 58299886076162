/*! *********************************************************************
 *
 * Copyright 2019 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 *************************************************************************
 */

import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  :root {
  /**************/
  /*   COLORS   */
  /**************/
  --color-gray-50: #080808;
  --color-gray-75: #1a1a1a;
  --color-gray-100: #1e1e1e;
  --color-gray-200: #2c2c2c;
  --color-gray-300: #393939;
  --color-gray-400: #494949;
  --color-gray-500: #5c5c5c;
  --color-gray-600: #7c7c7c;
  --color-gray-700: #a2a2a2;
  --color-gray-800: #c8c8c8;
  --color-gray-900: #efefef;
  --color-red-400:  #d7373f;
  --color-blue-400: #1473e6;
  --color-blue-500: #2680eb;
  --color-green-400: #268e6c;

  --root-background-color: var(--color-gray-100);
  --header-background: var(--color-gray-50);
  --dropzone-background: var(--color-gray-100);
  --sidebar-background: var(--color-gray-200);
  --card-background: var(--color-gray-200);
  --card-darker-background: var(--color-gray-100);
  --field-background: var(--color-gray-75);
  --metadata-background: var(--color-gray-75);
  --placeholder-background: var(--color-gray-600);
  --attachment-background: var(--color-gray-75);

  --sidebar-border-color: var(--color-gray-500);
  --sidebar-title-color: var(--color-gray-900);
  --scroll-color: var(--color-gray-700);
  --menu-border-color: var(--header-background);
  --menu-facets-divider: var(--color-gray-500);
  --metadata-border-color: var(--color-gray-700);
  --field-border-color: var(--color-gray-700);
  --footer-divider-color: var(--color-gray-700);
  --attachment-border-color: var(--color-gray-300);
  --attachment-divider-color: var(--attachment-border-color);
  --page-header-border-color: var(--color-gray-700);
  --placeholder-color: var(--color-gray-800);

  --text-color: var(--color-gray-900);
  --metadata-color: var(--color-gray-700);
  --links-color: var(--color-blue-500);
  --illustration-color: var(--color-gray-800);

  --asset-item-archived-status-color: var(--color-gray-700);
  --asset-item-border-color: var(--color-gray-600);
  --asset-otem-background-color: var(--color-gray-200);
  --attachment-close-icon-color: var(--color-gray-700);
  --attachment-close-icon-hover-color: var(--color-gray-900);
  --action-color: var(--color-gray-700);
  --action-highlight-color: var(--color-gray-900);
  --action-active-color: var(--color-blue-400);

  --root-color: var(--color-gray-900);
  --success: var(--color-green-400);
  --error: var(--color-red-400);
  --links-color: var(--color-gray-900);
  
  /*************/
  /*   SIZES   */
  /*************/
  --header-height: 50px;
  --search-height: 50px;
  --footer-height: 30px;
  --sidebar-width: 240px;

  /* viewport - header - search - footer */
  --content-height: calc(100vh - var(--header-height) - var(--footer-height));
  --left-sidebar-height: calc(100vh - var(--search-height) - var(--footer-height));
  --left-menu-height: calc(100vh - var(--header-height) - var(--search-height) - var(--footer-height));
}

* {
  box-sizing: border-box;
}

html {
  background-color: var(--root-background-color);
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;

  .react-spectrum-provider {
    min-height: 100%;
  }
}

h1, body {
  font-family: 'adobe-clean', 'Source Sans Pro', -apple-system,
    BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: var(--links-color);
  text-decoration: none;
}

.error-boundary,
.not-found {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 24px;
  margin: auto;

  .error-boundary_code {
    font-size: 0.6rem;
    color: var(--text-color);
  }

  a {
    margin-top: 30px;
    width: fit-content;
  }
}

.third-sticky {
  position: sticky;
  top: var(--header-height);
}

.third-height {
  height: var(--search-height);
}

.content-height {
  min-height: var(--content-height);
}

.left-menu-height {
  height: var(--left-menu-height)
}

.left-sidebar-height {
  height: var(--left-sidebar-height);
}

.third-header {
  background: var(--sidebar-background) 0% 0% no-repeat padding-box;
}

.spectrum--darkest, 
.spectrum--darkest .spectrum-Body {
  color: var(--root-color) !important;
}

/* Not standard Spectrum field background color */
.spectrum--darkest .spectrum-Textfield {
  background-color: var(--field-background) !important;
}

/* Not standard Spectrum SideNav custom width to match 240px */
.spectrum--medium .react-spectrum-SideNav {
  width: 206px !important;
}

/* placeholders animations */
@keyframes placeholder-opacity {
  0% {
    filter: opacity(20%);
  }
  100% {
    filter: opacity(25%);
  }
}

@keyframes heartbeat {
  from {
    transform: scale(1);
    transform-origin: center center;
    animation-timing-function: ease-out;
  }
  10% {
    transform: scale(0.99);
    animation-timing-function: ease-in;
  }
  17% {
    transform: scale(0.995);
    animation-timing-function: ease-out;
  }
  33% {
    transform: scale(0.985);
    animation-timing-function: ease-in;
  }
  45% {
    transform: scale(1);
    animation-timing-function: ease-out;
  }
}



  ::-webkit-scrollbar {
    width: 0.5em;
    height:   0.5em;
  }
  ::-webkit-scrollbar-track {
    height: 1em;
    border: 1px solid rgba(0, 0, 0, 0);
    border-radius: 10px;
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    height: 1em;
    border: 1px solid rgba(0, 0, 0, 0);
    border-radius: 10px;
    background-clip: padding-box;
    background-color: var(--scroll-color);
  }
  ::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
  }
  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }

  /* for Firefox */
  @supports (scrollbar-color: transparent transparent){
    scrollbar-color: var(--scroll-color) transparent;
    scrollbar-width: thin;
  }

`;
