/*! *********************************************************************
 *
 * Copyright 2019 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 *************************************************************************
 */

import React, { useState } from 'react';

import { assetFactory } from '@3di-cloud-substance/models';

const AssetContext = React.createContext({});

export function AssetProvider({ init = {}, children }) {
  const [asset, setAsset] = useState(assetFactory(init));

  function update(updatedAsset) {
    setAsset(assetFactory(updatedAsset));
  }

  return (
    <AssetContext.Provider value={{ asset, update }}>
      {children}
    </AssetContext.Provider>
  );
}

export default AssetContext;
