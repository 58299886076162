/*! *********************************************************************
 *
 * Copyright 2019 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 *************************************************************************
 */

import React from 'react';
import _replace from 'lodash/replace';
import _capitalize from 'lodash/capitalize';
import { matchPath, withRouter } from 'react-router-dom';

import { ROUTES } from '../config';

/**
 * Create a route by replacing params
 * @param {string} route string of the route containing the params
 * @param {object} params object of key value (what to replace by what)
 */
function createRoute(route, params) {
  if (!route) throw new Error('No route provided');
  if (!params) return route;

  let myRoute = `${route}`;

  const keys = Object.keys(params);

  for (let key of keys) {
    myRoute = _replace(myRoute, `:${key}`, params[key]);
  }

  return myRoute;
}

/**
 * Get the current active matching route
 */
const withActiveRoute = (Component) => {
  const ActiveRoute = (props) => {
    let activeKey;
    for (const routeKey in ROUTES) {
      const match = matchPath(props.location.pathname, ROUTES[routeKey]);
      const isActiveRoute = match && match.isExact;
      if (isActiveRoute) {
        activeKey = routeKey;
        break;
      }
    }
    const activeRoute = ROUTES[activeKey];

    activeKey = _capitalize(_replace(activeKey, /([A-Z])/g, ' $1'));

    return (
      <Component {...props} activeRoute={activeRoute} pageName={activeKey} />
    );
  };

  return withRouter(ActiveRoute);
};

export { createRoute, withActiveRoute };
