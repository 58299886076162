/*! *********************************************************************
 *
 * Copyright 2019 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 *************************************************************************
 */

import React, { Suspense } from 'react';
import Helmet from 'react-helmet';

import Wait from '@react/react-spectrum/Wait';

import SpectrumProvider from '../components/SpectrumProvider';
import Header from '../components/Header';
import Footer from '../components/Footer';
import SinglePageLayout from './SinglePageLayout';
import { GlobalStyle } from './GlobalStyle';

const DEFAULT_ASSET_IMAGE_BACKGROUND_COLOR = '30,30,30';
const GREYED_ASSET_IMAGE_BACKGROUND_COLOR = '44,44,44';

/**
 * Global layout for the whole application.
 * While include : navbar / header / content / footer
 */
const GlobalLayout = ({ children }) => {
  return (
    <SpectrumProvider>
      <Suspense fallback={<SinglePageLayout content={<Wait size="L" />} />}>
        <GlobalStyle />
        <Header />
        <Helmet>
          <title>Substance 3D Community Assets</title>
        </Helmet>
        {children}
        {/*
         * Don't move the Footer causes performance issues due to the OneTrust cookie banner
         * Unless we change the way we handle the cookie banner
         */}
        <Footer />
      </Suspense>
    </SpectrumProvider>
  );
};

export {
  DEFAULT_ASSET_IMAGE_BACKGROUND_COLOR,
  GREYED_ASSET_IMAGE_BACKGROUND_COLOR,
};
export default GlobalLayout;
