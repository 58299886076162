/*! *********************************************************************
 *
 * Copyright 2019 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 *************************************************************************
 */

import React, { useReducer, useEffect, useCallback, useContext } from 'react';
import { stringify } from 'query-string';
import { useMutation } from '@apollo/react-hooks';
import _filter from 'lodash/filter';

import purchaseAssetQuery from '../graphql/purchaseAsset.gql';
import UserContext from '../contexts/UserContext';
import { assetEvent } from '../utils/Analytics';
import ims from '../lib/ims';

export const ERROR_NO_DOWNLOAD_URL = 'ERROR_NO_DOWNLOAD_URL';
export const ERROR_BUYING_ASSETS = 'ERROR_BUYING_ASSETS';
export const ERROR_GRAPHQL = 'ERROR_GRAPHQL';
const GRAPHQL_ERROR_HEADER = 'GraphQL error: ';

const DownloadContext = React.createContext({});

export const DownloadProvider = DownloadContext.Provider;

const initialState = {
  error: null,
  fetching: false,
  notLogged: false,
  asset: null,
  downloadUrl: null,
  backFromLogin: false,
};

function clearAsset() {
  sessionStorage.removeItem('asset');
  sessionStorage.removeItem('downloadUrl');
}

function storeAsset(asset, downloadUrl) {
  sessionStorage.setItem('asset', JSON.stringify(asset));
  sessionStorage.setItem('downloadUrl', downloadUrl);
}

function reducer(state, { type, payload }) {
  switch (type) {
    case 'init':
      return initialState;
    case 'fetching':
      return {
        ...state,
        error: null,
        fetching: true,
      };
    case 'buyingAsset':
      return {
        ...state,
        error: null,
        fetching: true,
        asset: payload.asset,
        downloadUrl: payload.downloadUrl,
      };
    case 'notLogged':
      storeAsset(payload.asset, payload.downloadUrl);
      return {
        ...state,
        notLogged: true,
      };
    case 'error':
      return { ...initialState, error: payload };
    default:
      return state;
  }
}

const DownloadManager = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { user, account, login, purchaseAsset } = useContext(UserContext);
  const [buyAsset, { data, error }] = useMutation(purchaseAssetQuery);

  const downloadAsset = useCallback(
    async (asset, downloadUrl) => {
      if (!downloadUrl) {
        assetEvent({
          action: 'Download error : No downloadUrl',
          nonInteraction: true,
        });
        dispatch({ type: 'error', payload: { code: ERROR_NO_DOWNLOAD_URL } });
        return;
      }
      const accessToken = ims.accessToken;
      const queryString = stringify({
        accessToken: accessToken,
        organizationId: account.id,
      });
      assetEvent({
        action: 'Download success',
        nonInteraction: true,
      });
      window.location = `${downloadUrl}?${queryString}`;
      dispatch({ type: 'init' });
    },
    //eslint-disable-next-line
    [user],
  );

  const download = useCallback(
    async (asset, downloadUrl) => {
      if (!asset) return;

      if (!user) {
        dispatch({ type: 'notLogged', payload: { asset, downloadUrl } });
        login();
        return;
      }

      dispatch({ type: 'fetching' });
      const assetFound = _filter(
        account.assetIds,
        (userAssetId) => userAssetId === asset.id,
      );
      if (assetFound.length === 0) {
        try {
          dispatch({ type: 'buyingAsset', payload: { asset, downloadUrl } });
          await buyAsset({
            variables: {
              assetId: asset.id,
              organizationId: account.id,
            },
          });
          assetEvent({
            action: 'Bought success',
            nonInteraction: true,
          });
        } catch (e) {
          assetEvent({
            action: 'Bought error',
            nonInteraction: true,
          });
          dispatch({ type: 'error', payload: { code: ERROR_BUYING_ASSETS } });
          return;
        }
      } else {
        downloadAsset(asset, downloadUrl);
      }
    },
    //eslint-disable-next-line
    [user, account],
  );

  /**
   * Check if an asset need to be downloaded
   */
  const checkDownload = () => {
    const asset = JSON.parse(sessionStorage.getItem('asset'));
    const downloadUrl = sessionStorage.getItem('downloadUrl');
    const needDownload = asset !== null && downloadUrl !== null;
    if (needDownload) {
      clearAsset();
      download(asset, downloadUrl);
    }
  };

  const init = () => {
    dispatch({ type: 'init' });
  };

  useEffect(() => {
    if (data && state.asset && state.downloadUrl && !error) {
      downloadAsset(state.asset, state.downloadUrl);
      purchaseAsset(state.asset);
    }
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if (user) {
      document.addEventListener('USER_LOGGED', () => {
        checkDownload();
      });
    }
    //eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    if (!error) return;

    if (error.message || error.code) {
      assetEvent({
        action: `Download on error : ${error.code}`,
        nonInteraction: true,
      });
      dispatch({
        type: 'error',
        payload: {
          code: ERROR_GRAPHQL,
          message: error.message.replace(GRAPHQL_ERROR_HEADER, ''),
        },
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  return (
    <DownloadProvider
      value={{ error: state.error, init, download, fetching: state.fetching }}
    >
      {children}
    </DownloadProvider>
  );
};

export default DownloadContext;
export { DownloadManager, DownloadContext };
