/*! *********************************************************************
 *
 * Copyright 2019 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 *************************************************************************
 */

import {
  CORPORATE_WEBSITE_URL,
  GRAPHQL_URL,
  ROUTES,
  ADOBEID_CLIENT_ID,
  IMSLIB2_URL,
  FEDS_SCRIPT_URL,
  OT_DOMAIN_ID,
  PRODUCTION,
  ADOBE_3D_URL,
} from './env-config';

export const COMMUNITY_ASSET_BRAND_NAME = 'Substance 3D Community Assets';

export const ASSET_TYPE_MODELS = 'MODELS';
export const ASSET_TYPE_MATERIALS = 'MATERIALS';

export const ADOBE_URL = 'https://www.adobe.com';
export const ADOBE_PROFILE_URL = 'https://account.adobe.com/profile';

export const CORPORATE_WEBSITE_USER_URL = `${CORPORATE_WEBSITE_URL}/user`;
export const CORPORATE_WEBSITE_LEGAL_URL = `https://www.adobe.com/legal/terms.html`;
export const CORPORATE_WEBSITE_PRIVACY_URL = `https://www.adobe.com/privacy/policy.html`;
export const CORPORATE_WEBSITE_CG_URL = `${CORPORATE_WEBSITE_URL}/substance-share-community-guidelines`;
export const CORPORATE_WEBSITE_PRIVACY_RIGHTS =
  'https://www.adobe.com/go/ca-rights';

export const ADOBE_IMS_CONFIG = {
  client_id: ADOBEID_CLIENT_ID,
  environment: PRODUCTION ? 'prod' : 'stg1',
  scope: 'account_type,openid,AdobeID,read_organizations',
};

export const ADOBE_LOCALE_APP_NAME = 's3diweb';

export const LOCALE_QUERY = 'lang';

export const DEFAULT_LOCALE = 'en-US';

export const CJK_LOCALES = ['zh-Hans', 'zh-Hant', 'ja-JP', 'ko-KR'];

export const CDN_LOADER = PRODUCTION ? 'share' : 'share-develop';

/** Version for getting up to date file (arbitrary based on date of update : yyyymmdd) */
export const CDN_CACHE_VERSION = '20211012';

export {
  GRAPHQL_URL,
  ROUTES,
  IMSLIB2_URL,
  FEDS_SCRIPT_URL,
  OT_DOMAIN_ID,
  PRODUCTION,
  ADOBE_3D_URL,
};

export const LMT_SERVICE_URL = PRODUCTION
  ? 'https://lmt-g11n.adobe.io'
  : 'https://lmt-g11n-stage.adobe.io';

export const LMT_SERVICE_CUSTOMER_TOKEN = PRODUCTION
  ? '11ebea4bfde9b26a83739707c16b6c88'
  : '11ebea4b65a76cdd98a6012dfb997eee';

export const DB_SUPPORTED_INDEXING_LOCALES = ['en-US'];
