/*! *********************************************************************
 *
 * Copyright 2019 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 *************************************************************************
 */

import {
  analytics,
  AnalyticsTrackData,
  EVENTS_TYPE,
} from '@3di-cloud-web/analytics';
import { i18nLanguageDetector } from '@3di-cloud-web/i18n';

import { PRODUCTION } from '../config';

const SHARE_EVENT_PREFIX = 'SubstanceShareEvent';

export async function init() {
  const options = new Map([
    ['environment', PRODUCTION ? 'production' : 'stage'],
    [
      'additionalAccounts',
      PRODUCTION ? 'adbadbsubstanceprod' : 'adbsubstancedev',
    ],
    ['property', 'global'],
  ]);
  await analytics.init(options);
}

export function track(pageName) {
  const location = global.location;

  // Extract lagnuage and region
  const language = i18nLanguageDetector.language;
  const region = navigator.language.split('-')[1];

  // Complete breadCrumbs with current page name
  const breadCrumbs = ['Home'];
  if (pageName !== 'Home') {
    breadCrumbs.push(pageName);
  }

  const data = new Map();
  data.set('page.pageInfo.siteSection', pageName);
  data.set(
    'page.pageInfo.template',
    location.pathname + location.search + location.hash,
  );
  data.set('page.pageInfo.language', language);
  data.set('page.pageInfo.geoRegion', region);
  data.set('page.pageInfo.issueDate', new Date());
  data.set('page.pageInfo.breadCrumbs', breadCrumbs);

  analytics.track(new AnalyticsTrackData(data));
}

export function event({ category, action, label, value, nonInteraction }) {
  analytics.event(`${SHARE_EVENT_PREFIX} - ${action}`, {
    ...(category && { [EVENTS_TYPE.CATEGORY]: category }),
    ...(label && { [EVENTS_TYPE.LABEL]: label }),
    ...(value && { [EVENTS_TYPE.VALUE]: value }),
    ...(nonInteraction && { [EVENTS_TYPE.NON_INTERACTION]: nonInteraction }),
  });
}

export function userEvent({ action, label, value, nonInteraction }) {
  event({
    category: 'User',
    action,
    label,
    value,
    nonInteraction,
  });
}

export function uploadEvent({ action, label, value, nonInteraction }) {
  event({
    category: 'Upload',
    action,
    label,
    value,
    nonInteraction,
  });
}

export function uploadSuccessEvent({ action, label, value, nonInteraction }) {
  event({
    category: 'Upload Success Modal',
    action,
    label,
    value,
    nonInteraction,
  });
}
export function uploadErrorEvent({ action, label, value, nonInteraction }) {
  event({
    category: 'Upload Error Modal',
    action,
    label,
    value,
    nonInteraction,
  });
}

export function profileEvent({ action, label, value, nonInteraction }) {
  event({
    category: 'Profile',
    action,
    label,
    value,
    nonInteraction,
  });
}

export function profileEditEvent({ action, label, value, nonInteraction }) {
  event({
    category: 'ProfileEdit',
    action,
    label,
    value,
    nonInteraction,
  });
}

export function searchBarEvent({ action, label, value, nonInteraction }) {
  event({
    category: 'Searchbar',
    action,
    label,
    value,
    nonInteraction,
  });
}

export function categoriesEvent({ action, label, value, nonInteraction }) {
  event({
    category: 'CategoriesSideBar',
    action,
    label,
    value,
    nonInteraction,
  });
}

export function assetEvent({ action, label, value, nonInteraction }) {
  event({
    category: 'Asset',
    action,
    label,
    value,
    nonInteraction,
  });
}

export function assetEditEvent({ action, label, value, nonInteraction }) {
  event({
    category: 'AssetEdit',
    action,
    label,
    value,
    nonInteraction,
  });
}

export function assetListEvent({ action, label, value, nonInteraction }) {
  event({
    category: 'AssetList',
    action,
    label,
    value,
    nonInteraction,
  });
}

export function reportEvent({ action, label, value, nonInteraction }) {
  event({
    category: 'Report',
    action,
    label,
    value,
    nonInteraction,
  });
}

export function headerEvent({ action, label, value, nonInteraction }) {
  event({
    category: 'Header',
    action,
    label,
    value,
    nonInteraction,
  });
}

export function footerEvent({ action, label, value, nonInteraction }) {
  event({
    category: 'Footer',
    action,
    label,
    value,
    nonInteraction,
  });
}

export function generalErrorEvent({ action, label, value, nonInteraction }) {
  event({
    category: 'General Error',
    action,
    label,
    value,
    nonInteraction,
  });
}

export function timing(action, value) {
  analytics.event(`${SHARE_EVENT_PREFIX} - ${action}`, {
    ...(value && { [EVENTS_TYPE.VALUE]: value }),
  });
}
