/*! *********************************************************************
 *
 * Copyright 2019 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 *************************************************************************
 */

import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import _startCase from 'lodash/startCase';
import _get from 'lodash/get';

import Button from '@react/react-spectrum/Button';
import Dropdown from '@react/react-spectrum/Dropdown';
import Heading from '@react/react-spectrum/Heading';
import {
  Menu,
  MenuItem,
  MenuDivider,
  MenuHeading,
} from '@react/react-spectrum/Menu';
import UserIcon from '@react/react-spectrum/Icon/User';
import Wait from '@react/react-spectrum/Wait/js/Wait';
import AlertIcon from '@react/react-spectrum/Icon/Alert';

import { ROUTES } from '../config';
import UserContext from '../contexts/UserContext';
import { headerEvent } from '../utils/Analytics';
import { createRoute } from '../lib/routeUtils';
import { MOBILE_MAX_WIDTH } from '../hooks/useDevice';
import UserAvatar from './UserAvatar';

function UserCard({ user }) {
  const avatarUrl = _get(user, 'avatarUrl');
  const name = _startCase(_get(user, 'name'));
  const email = _startCase(_get(user, 'email'));

  return (
    <UserCardContainer>
      <UserAvatar avatarUrl={avatarUrl} size="L" />
      <div>
        <Heading variant="subtitle1">{name}</Heading>
        {email}
      </div>
    </UserCardContainer>
  );
}

function UserMenuButton(props) {
  const intl = useIntl();
  const history = useHistory();
  const { login, logout, user, fetching, error } = useContext(UserContext);
  const { windowSize } = props;

  const handleSelectMenu = (value) => {
    switch (value) {
      case 'profile':
        headerEvent({
          action: `Clicked view profile`,
        });
        history.push({
          pathname: createRoute(ROUTES.publicProfilePage, { id: user.id }),
        });
        break;
      case 'signOut':
        headerEvent({
          action: 'Clicked on sign out',
        });
        logout();
        break;
      default:
        return;
    }
  };

  if (fetching) {
    return (
      <Container>
        <Wait size="S" />
      </Container>
    );
  }

  const handleLoginClick = () => {
    headerEvent({
      action: 'Clicked on sign in',
    });
    login();
  };

  return (
    <Container>
      {user ? (
        <Dropdown alignRight onSelect={handleSelectMenu}>
          <Button
            quiet
            label={null}
            icon={
              <>
                <UserAvatar avatarUrl={user.avatarUrl} />{' '}
                <UserErrorIcon show={error} />
              </>
            }
            variant="tool"
          />
          <MenuContainer>
            <MenuHeading>
              <UserCard user={user} />
            </MenuHeading>
            <MenuDivider />
            {error ? (
              <ErrorMenuItem>
                {intl.formatMessage({
                  id: 'UserMenuViewErrorMessage',
                  defaultMessage:
                    'We were unable to retrieve your user data. Please log out and log in again or refresh the page.',
                  description:
                    'Error message displayed in the user menu in case of a good connexion IMS but error received from the backend',
                })}
              </ErrorMenuItem>
            ) : (
              <></>
            )}
            {!error ? (
              <MenuItem value="profile">
                {intl.formatMessage({
                  id: 'headerActionProfile',
                  defaultMessage: 'View Profile',
                })}
              </MenuItem>
            ) : (
              <></>
            )}
            <MenuDivider />
            <MenuItem value="signOut">
              {intl.formatMessage({
                id: 'headerActionSignOut',
                defaultMessage: 'Sign out',
              })}
            </MenuItem>
          </MenuContainer>
        </Dropdown>
      ) : windowSize.width > MOBILE_MAX_WIDTH ? (
        <Button quiet variant="primary" onClick={handleLoginClick}>
          {intl.formatMessage({
            id: 'headerActionSignIn',
            defaultMessage: 'Sign in',
          })}
        </Button>
      ) : (
        <Button
          label={null}
          variant="tool"
          icon={<UserIcon />}
          onClick={handleLoginClick}
        />
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 8px;

  @media (max-width: ${MOBILE_MAX_WIDTH}px) {
    margin: 0;
  }
`;

const MenuContainer = styled(Menu)`
  .spectrum-Menu-sectionHeading {
    text-transform: unset;
    margin: 1.5rem 1rem;
  }

  .spectrum-Menu-divider {
    margin: 0;
  }
`;

const UserCardContainer = styled.div`
  display: flex;

  & > div {
    margin-right: 24px;
  }
`;

const UserErrorIcon = styled(AlertIcon)`
  position: absolute;
  bottom: 0;
  right: 0;
  fill: var(--color-red-400);
  width: 20px;
  height: 20px;
  display: ${({ show }) => (show ? 'block' : 'none')};
`;

const ErrorMenuItem = styled.div`
  color: var(--color-red-400) !important;
  max-width: 250px;
  margin: 0.7rem;
`;

export default UserMenuButton;
