/*! *********************************************************************
 *
 * Copyright 2019 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 *************************************************************************
 */

import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from 'apollo-cache-inmemory';
import { i18nLanguageDetector } from '@3di-cloud-web/i18n';
import { assetFactory } from '@3di-cloud-substance/models';
import fragmentTypes from '../graphql/fragmentTypes.json';

import _get from 'lodash/get';

import { DEFAULT_LOCALE, GRAPHQL_URL } from '../config';
import ims from '../lib/ims';

// Needed for GraphQL interface (Should be schema but it works like this)
// issue : https://github.com/apollographql/apollo-client/issues/3397
// How to use IntrospectionFragmentMatcher => https://www.apollographql.com/docs/react/data/fragments/#fragments-on-unions-and-interfaces
const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: fragmentTypes,
});

const asyncAuthLink = setContext(async (_, { headers }) => {
  const language = i18nLanguageDetector.language || DEFAULT_LOCALE;
  const token = ims.accessToken;

  return {
    headers: {
      ...headers,
      'Accept-Language': language,
      ...(token && {
        Authorization: `Bearer ${token}`,
      }),
    },
  };
});

const errorLink = onError(({ operation, response, graphQLErrors }) => {
  if (operation.operationName === 'Asset') {
    const asset = assetFactory(_get(response, 'data.asset', {}));
    if (graphQLErrors && asset && asset.canBeDisplayed) {
      response.errors = null;
    }
  }
});

const cache = new InMemoryCache({ fragmentMatcher });
const httpLink = createHttpLink({ uri: GRAPHQL_URL, useGETForQueries: false });

const link = ApolloLink.from([errorLink, asyncAuthLink, httpLink]);

const apolloClient = new ApolloClient({
  link: link,
  resolvers: {},
  cache,
  defaultOptions: {
    mutate: {
      errorPolicy: 'all',
    },
  },
});

export function clearAssetsListCache() {
  const cache = apolloClient.cache;
  const reg = `ROOT_QUERY.assets`;
  var test = new RegExp(reg, 'g');
  Object.keys(cache.data.data).forEach((key) => {
    // removing all cached data that are a PaginatedAssetList
    if (key.match(test) && cache.data.get(key).items) cache.data.delete(key);
  });
}

export default apolloClient;
