/*! *********************************************************************
 *
 * Copyright 2019 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 *************************************************************************
 */

import React from 'react';

import _findIndex from 'lodash/findIndex';
import _cloneDeep from 'lodash/cloneDeep';

export const QUERY_CHANGE = 'query_change';
export const ADD_ASSETS = 'add_assets';
export const DELETE_ASSETS = 'delete_assets';
export const REFRESH_ASSETS = 'refresh_assets';
export const ERROR = 'error';

function deleteAsset(assets, asset) {
  const assetIndex = _findIndex(assets, ['id', asset.id]);
  let refreshedAssets = _cloneDeep(assets);
  refreshedAssets.splice(assetIndex, 1);
  return refreshedAssets;
}

function refreshAsset(assets, asset) {
  const assetIndex = _findIndex(assets, ['id', asset.id]);
  let refreshedAssets = _cloneDeep(assets);
  refreshedAssets.splice(assetIndex, 1, asset);
  return refreshedAssets;
}

export function assetsReducer(state, action) {
  switch (action.type) {
    case QUERY_CHANGE:
      return {
        ...state,
        query: action.query,
        assets: null,
        cursor: null,
        err: null,
      };
    case ADD_ASSETS:
      return { ...state, assets: action.assets, cursor: action.cursor };
    case DELETE_ASSETS:
      return { ...state, assets: deleteAsset(state.assets, action.asset) };
    case REFRESH_ASSETS:
      return { ...state, assets: refreshAsset(state.assets, action.asset) };
    case ERROR:
      return { ...state, err: action.error };
    default:
      throw new Error();
  }
}

export const AssetsContext = React.createContext();
