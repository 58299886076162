/*! *********************************************************************
 *
 * Copyright 2019 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 *************************************************************************
 */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import Heading from '@react/react-spectrum/Heading';
import Button from '@react/react-spectrum/Button';
import { Accordion, AccordionItem } from '@react/react-spectrum/Accordion';
import { generalErrorEvent } from '../utils/Analytics';

import { ROUTES } from '../config';
import GlobalLayout from '../layout/GlobalLayout';

export default function ErrorPage({ message, errorCode }) {
  const intl = useIntl();

  useEffect(() => {
    generalErrorEvent({
      action: `General error ${errorCode}`,
      value: message,
      nonInteraction: true,
    });
  }, [errorCode, message]);

  return (
    <GlobalLayout>
      <div className="error-boundary">
        <Heading>
          <FormattedMessage
            id="errorPageTitle"
            defaultMessage="Oups! Something went wrong..."
            description="Generic error page title"
          />
        </Heading>
        <Heading variant="pageTitle">
          {intl.formatMessage({
            id: 'errorPageDescription',
            defaultMessage: '3D assets will be back shortly',
            description: 'Generic error page description',
          })}
        </Heading>

        {message && (
          <StyledAccordion>
            <AccordionItem
              header={intl.formatMessage({
                id: 'errorPageShowMore',
                defaultMessage: 'Show More',
                description: 'Accordion to show the full error message',
              })}
            >
              {message}
            </AccordionItem>
          </StyledAccordion>
        )}
        <span className="error-boundary_code">{errorCode}</span>
        <Button variant="secondary" element="a" href={ROUTES.home}>
          <FormattedMessage
            id="notFoundPageHome"
            defaultMessage="Back to homepage"
            description="Back to homepage button label on a notfound page"
          />
        </Button>
      </div>
    </GlobalLayout>
  );
}

const StyledAccordion = styled(Accordion)`
  .spectrum-Accordion-item {
    border: none;
    display: flex;
    flex-direction: column;
    align-items: center;

    .spectrum-Accordion-itemHeading {
      position: relative;
    }
  }
`;

ErrorPage.propTypes = {
  message: PropTypes.string,
  errorCode: PropTypes.string,
  // If the homepage button is shown
  homepage: PropTypes.bool,
};
