/*! *********************************************************************
 *
 * Copyright 2019 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 *************************************************************************
 */

import React, { Component, useContext } from 'react';
import _slice from 'lodash/slice';
import _clone from 'lodash/clone';
import _concat from 'lodash/concat';
import _findIndex from 'lodash/findIndex';
import _isFunction from 'lodash/isFunction';

import { addToast, Toast } from '@react/react-spectrum/Toast';
import * as ToastState from '@react/react-spectrum/Toast/js/state';

const NotificationContext = React.createContext();

// Provider
// ==============================
export class NotificationProvider extends Component {
  count = 0;
  state = { notifications: [] };

  add = ({
    content,
    variant,
    onAction,
    actionLabel,
    timeout = 3000,
    container,
    placement = 'top center',
  }) => {
    const id = this.count++;
    ToastState.TOAST_PLACEMENT = placement;

    addToast(
      <Toast
        closable
        variant={variant}
        actionLabel={actionLabel}
        onAction={this.onAction(onAction)}
        onClose={this.onClose(id)}
      >
        {content}
      </Toast>,
      timeout,
      container,
    );

    this.setState((prevState) => {
      const notification = [{ id, content, type: variant }];
      const notifications = _concat(prevState.notifications, notification);
      return { notifications };
    });
  };

  remove(id) {
    this.setState((prevState) => {
      let notifications = _clone(prevState.notifications);
      const idx = _findIndex(notifications, (i) => i.id === id);

      notifications =
        idx !== -1
          ? _concat(
              _slice(notifications, 0, idx),
              _slice(notifications, idx + 1),
            )
          : notifications;

      return { notifications };
    });
  }

  onAction(fn) {
    return () => (_isFunction(fn) ? fn() : null);
  }

  onClose(id) {
    return () => this.remove(id);
  }

  render() {
    const context = {
      add: this.add,
      notifications: _clone(this.state.notifications),
    };

    return (
      <NotificationContext.Provider value={context}>
        {this.props.children}
      </NotificationContext.Provider>
    );
  }
}

export const useNotifications = () => useContext(NotificationContext);
